import _ from 'lodash';
import { REFRESH_EVENT } from 'widget/utils/reload';
import EVENTS from 'shared/constants/events';
import { BI_EVENT } from 'shared/bi/constants';

import {
  restoreAction,
  createEventPublisher,
  createWorkerHandlerMiddleware,
  // createProxiesMiddleware,
} from './lib';
import { createMemoryHistory } from 'history';
import WixEvents from 'shared/constants/wix-sdk';
import { createAndSetupMonitor } from 'loggers/sentry';
import { setInitialAppSettings } from 'widget/redux/client/actions/app-settings';
import { setHydratedDataFromSource } from 'widget/redux/client/hydrated-data/hydrated-data';
import { fetchVODExperiments } from 'api/public/experiments/experiments';
import { fetchTranslations } from 'api/public/translations';
// import { ALL_PROXIES } from './registerProxies';
import { SET_PUBLIC_PATH } from 'services/locale/config';
import { getBiToken } from 'shared/worker/getBiToken';
import DEVICE_TYPES from 'shared/constants/device-types';
import { VIDEO_APP_DEF_ID } from '@wix/wix-vod-constants/dist/common/app-def-id';
import { configureAxios } from 'shared/configure-axios';
import axios from 'axios';

function wrapError(fn, errHandler) {
  return (...args) => {
    let res;
    try {
      res = fn(...args);
    } catch (err) {
      return errHandler(err);
    }
    if (res && typeof res === 'object' && res.then && res.catch) {
      return res.catch(errHandler);
    }
    return res;
  };
}

function getFromGlobal() {
  return _.pick(global, [
    '__LOCALE__',
    '__TRANSLATIONS__',
    '__SINGLE_VIDEO__',
    '__VIDEOS__',
    '__MEMBER_INFO__',
    'viewMode',
    '__CURRENT_SITE_USER__',
    'biToken',
    '__EXPERIMENTS__',
    '__MIGRATION_INFO__',
    'windowSize',
    '__SITE_URL__',
    '__EDITOR_TYPE__',
    '__CHANNEL__',
    'instance',
    'instanceId',
    'uid',
    'compId',
    'deviceType',
  ]);
}

function createControllerWithErrorLogging(createController, monitor) {
  function onError(err) {
    monitor.captureException(err);
    if (__DEV__) {
      console.error(err);
    }
    throw err;
  }

  return createController()
    .then(controller => {
      return {
        pageReady: wrapError(controller.pageReady, onError),
      };
    })
    .catch(onError);
}

function getInitialPubSubEvents() {
  return {
    [REFRESH_EVENT]: [],
    [EVENTS.SESSION.LOGOUT_USER]: null,
    [EVENTS.PAYMENT.CANCEL_SUBSCRIPTION]: null,
    [BI_EVENT]: null,
  };
}
export function subscribeToEvents({ setProps, pubSub }) {
  const pubSubEvents = getInitialPubSubEvents();

  const debouncedSetProps = _.debounce(setProps, 100);

  const handlePubSubEventSeries = (eventName, isPersistent) => {
    pubSub.subscribe(
      eventName,
      data => {
        pubSubEvents[eventName] = [...pubSubEvents[eventName], data].slice(-10);
        debouncedSetProps({ pubSubEvents: { ...pubSubEvents } });
      },
      isPersistent,
    );
  };

  const handlePubSubEvent = (eventName, isPersistent) => {
    pubSub.subscribe(
      eventName,
      data => {
        pubSubEvents[eventName] = data;
        debouncedSetProps({ pubSubEvents: { ...pubSubEvents } });
      },
      isPersistent,
    );
  };

  const handleEvent = eventName => {
    if (global.Wix) {
      global.Wix.addEventListener(eventName, data => {
        pubSubEvents[eventName] = data;
        debouncedSetProps({ pubSubEvents: { ...pubSubEvents } });
      });
    }
  };

  const subscribe = () => {
    handlePubSubEventSeries(REFRESH_EVENT, true);
    handlePubSubEvent(EVENTS.SESSION.LOGOUT_USER);
    handlePubSubEvent(EVENTS.PAYMENT.CANCEL_SUBSCRIPTION);
    handlePubSubEvent(BI_EVENT);

    handleEvent(WixEvents.PAGE_NAVIGATION);
    handleEvent(WixEvents.SETTINGS_UPDATED);
    handleEvent(WixEvents.SITE_PUBLISHED);
    handleEvent(WixEvents.EDIT_MODE_CHANGE);
    handleEvent(WixEvents.STYLE_PARAMS_CHANGE);
  };

  subscribe();
}

function patchControllerConfig(controllerConfig, { getEvents }) {
  const pubSubEvents = getInitialPubSubEvents();
  const newSetProps = props => {
    controllerConfig.setProps({ events: getEvents(), pubSubEvents, ...props });
  };
  return { ...controllerConfig, setProps: newSetProps };
}

export const getSiteLocale = ({ wixCodeApi, styleParams }) => {
  const { window, site } = wixCodeApi;
  const { multilingual } = window;

  if (multilingual.isEnabled) {
    return multilingual.currentLanguage;
  }

  if (styleParams && styleParams.fonts && styleParams.fonts.language) {
    return styleParams.fonts.language;
  }

  return site.language;
};

export function createViewerScript(
  { createWorkerHandlerFromConfig },
  { getStoreBuilder, isSlave = false },
) {
  function getPageReadyData({ wixCodeApi }) {
    const currentPageId = wixCodeApi.site.currentPage.id;
    return { currentPageId };
  }

  async function getHydratedSource({
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    config,
  }) {
    const isLoggedIn =
      wixCodeApi.user.currentUser && wixCodeApi.user.currentUser.loggedIn;
    const viewMode = wixCodeApi.window.viewMode.toLowerCase();
    const uid = isLoggedIn ? wixCodeApi.user.currentUser.id : null;
    const siteOwnerId = platformAPIs.bi.ownerId;
    const { instanceId, instance } = appParams;
    const __SITE_URL__ = wixCodeApi.location.baseUrl;

    const getEmail = async () => {
      try {
        return await wixCodeApi.user.currentUser.getEmail();
      } catch (e) {
        return ' ';
      }
    };

    const __CURRENT_SITE_USER__ = isLoggedIn
      ? {
          id: wixCodeApi.user.currentUser.id,
          email: await getEmail(),
        }
      : null;

    const styleParams = config.style.styleParams;
    const siteLocale = getSiteLocale({ wixCodeApi, styleParams });
    const fullSiteUrl = wixCodeApi.location.url; // full url
    const deviceType = wixCodeApi.window.formFactor.toLowerCase();
    const biToken = getBiToken(instance, platformAPIs);
    const metaSiteId = platformAPIs.bi.metaSiteId;

    let widgetData = {};

    if (__OOI__) {
      const { baseUrl, protocol } = wixCodeApi.location;
      const domainWithProtocol = `${protocol}://${baseUrl.split('/')[2]}`;

      try {
        const { data } = await axios.get(
          `${domainWithProtocol}/wix-vod-server/widget-data`,
          {
            params: {
              channelId: styleParams.fonts.channelId,
              videoId: styleParams.fonts.videoId,
              instance,
              locale: siteLocale,
            },
          },
        );

        widgetData = data;
      } catch (e) {
        console.log(e);
      }
    }

    const experimentsPromise = (async () => {
      if (global.__EXPERIMENTS__) {
        return global.__EXPERIMENTS__;
      }

      if (widgetData.__EXPERIMENTS__) {
        return widgetData.__EXPERIMENTS__;
      }

      return fetchVODExperiments();
    })();

    const translationsPromise = (async () => {
      if (global.__TRANSLATIONS__) {
        return global.__TRANSLATIONS__;
      }

      if (widgetData.__TRANSLATIONS__) {
        return widgetData.__TRANSLATIONS__;
      }

      return fetchTranslations(siteLocale);
    })();

    const [__EXPERIMENTS__, __TRANSLATIONS__] = await Promise.all([
      experimentsPromise,
      translationsPromise,
    ]);

    return {
      siteOwnerId,
      instanceId,
      instance,
      viewMode,
      uid,
      appSettings: styleParams,
      compId,
      metaSiteId,
      ...widgetData,
      __SITE_URL__,
      __CURRENT_SITE_USER__,
      __EXPERIMENTS__,
      __TRANSLATIONS__,
      siteLocale,
      fullSiteUrl,
      deviceType,
      biToken,
    };
  }

  async function createStore({
    controllerConfig,
    setProps,
    publishEvent,
    performanceLogger,
    initialData,
  }) {
    const { wixCodeApi } = controllerConfig;
    // const url = wixCodeApi.location.url;
    const url = '/';

    const { instance } = initialData;

    // const workerHandler = createWorkerHandlerFromConfig(controllerConfig, {
    //   performanceLogger,
    // });

    const configData = { instance, url };

    const onInstanceChanged = event => {
      const newConfigData = { ...configData, instance: event.instance };
      configureAxios(configData);
      setProps({ configData: newConfigData });
    };

    wixCodeApi.site.onInstanceChanged(onInstanceChanged);

    const middleWares = [
      // createWorkerHandlerMiddleware(workerHandler),
      // createProxiesMiddleware(ALL_PROXIES),c
    ];

    const history = createMemoryHistory({ initialEntries: [url] });

    const { createStore: origCreateStore, setInitialState } = getStoreBuilder({
      isMobile: initialData.deviceType === DEVICE_TYPES.MOBILE,
    });

    const store = origCreateStore({
      configData,
      middleWares,
      history,
    });
    configureAxios(configData);

    store.dispatch(setHydratedDataFromSource(initialData));
    store.dispatch(setInitialAppSettings(initialData.appSettings));

    if (setInitialState) {
      await store.dispatch(setInitialState());
    }

    // const dispatchEv = action =>
    //   store.dispatch(restoreAction({ publishEvent, action }));

    // store.subscribe(() => {
    //   setProps({
    //     appState: store.getState(),
    //   });
    // });

    return {
      // dispatchEv,
      configData,
      appState: store.getState(),
    };
  }

  async function createAppController(controllerConfig, { monitor }) {
    const { wixCodeApi, setProps, platformAPIs } = controllerConfig;

    const { publishEvent, getEvents, consumeEvents } = createEventPublisher({
      setProps: controllerConfig.setProps,
    });

    controllerConfig = patchControllerConfig(controllerConfig, { getEvents });

    const [_hydratedSource] = await Promise.all([
      getHydratedSource(controllerConfig),
    ]);

    const appId = VIDEO_APP_DEF_ID;
    const widgetId = '144097ea-fea0-498e-ade7-e6de40127106';

    let performanceLogger;

    if (__OOI__) {
      performanceLogger = platformAPIs.fedOpsLoggerFactory.getLoggerForWidget({
        appId,
        widgetId,
      });
    } else {
      performanceLogger = platformAPIs.fedOpsLoggerFactory;
    }

    const captureException = (err, opts) => monitor.captureException(err, opts);

    if (__OOI__) {
      performanceLogger.appLoadStarted();
    }

    return {
      async pageReady() {
        const pageReadyData = getPageReadyData(controllerConfig);

        const initialData = {
          ...getFromGlobal(),
          ..._hydratedSource,
          ...pageReadyData,
        };

        const workerHandler = createWorkerHandlerFromConfig(controllerConfig, {
          performanceLogger,
          isSlave,
        });

        const dispatchEv = action =>
          workerHandler(restoreAction({ publishEvent, action }));

        const storeProps = await createStore({
          controllerConfig,
          setProps,
          // publishEvent, // we cant publish events before initial setProps (check on invalid video id from url)
          performanceLogger,
          initialData,
        });

        const componentProps = {
          ...storeProps,
          dispatchEv,
          consumeEvents,
          captureException,
          renderingEnv: wixCodeApi.window.rendering.env,
        };

        setProps(componentProps);

        // we cant publish events before initial setProps (check on invalid video id from url)
        subscribeToEvents({
          setProps,
          pubSub: platformAPIs.pubSub,
        });

        if (wixCodeApi.window.rendering.env === 'backend') {
          performanceLogger.appLoaded();
        }

        return componentProps.appState;
      },
    };
  }

  const createControllers = controllerConfigs => {
    if (__DEBUG__) {
      console.log('creating controllers', controllerConfigs);
    }

    const {
      publicPathOverride,
    } = controllerConfigs[0].wixCodeApi.location.query;

    if (publicPathOverride) {
      SET_PUBLIC_PATH(publicPathOverride);
      console.log(`overriding public path with ${publicPathOverride}`);
    }

    return controllerConfigs.map(config => {
      const monitor = createAndSetupMonitor(
        config.platformAPIs.monitoring.createMonitor,
        config,
      );

      return createControllerWithErrorLogging(
        () => createAppController(config, { monitor }),
        monitor,
      );
    });
  };

  return {
    createControllers,
  };
}
